import { useState } from "react"

import { Pager } from "src/components/Pager/Pager"
import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { useFetchHomeGroups } from "src/data/homeGroups/queries/homeGroupQueries"
import { InternalLink } from "src/ui/Link/InternalLink"
import { formatDate } from "src/utils/l10n"

const LIMIT = 15

export function ParadiseOrganizationHomeGroups({ orgId }: { orgId: string }) {
  const [offset, setOffset] = useState(0)

  const fetchHomeGroups = useFetchHomeGroups({
    orgId,
    filter: {
      offset,
      limit: LIMIT,
    },
  })

  const headers = [
    <div key={"id"}>Id</div>,
    <div key={"name"}>Name</div>,
    <div key={"created-at"}>Created at</div>,
  ]

  const rows =
    fetchHomeGroups.data?.homegroups.map((homeGroup) => (
      <>
        <InternalLink to="#">{homeGroup.id}</InternalLink>
        <div>{homeGroup.name}</div>
        <div>{formatDate({ date: homeGroup.created_at, timezone: "utc" })}</div>
      </>
    )) ?? []

  return (
    <div>
      <ParadiseDetailsSection title="Homegorups" />
      <ParadiseTable header={headers} rows={rows} />
      <Pager
        offset={offset}
        limit={LIMIT}
        setOffset={setOffset}
        totalCount={fetchHomeGroups.data?.paging.total_count}
      />
    </div>
  )
}
