import { EmptyState } from "src/components/EmptyState"
import { ParadiseDetailsLayout } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseOrganizationActions } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationActions"
import { ParadiseOrganizationAPIClients } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationAPIClients/ParadiseOrganizationAPIClients"
import { ParadiseOrganizationDevices } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationDevices/ParadiseOrganizationDevices"
import { ParadiseOrganizationHomeGroups } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationHomeGroups/ParadiseOrganizationHomeGroups"
import { ParadiseOrganizationHomes } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationHomes/ParadiseOrganizationHomes"
import { ParadiseOrganizationMembers } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationMembers/ParadiseOrganizationMembers"
import { ParadiseOrganizationOverview } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationOverview/ParadiseOrganizationOverview"
import { DetailsHeading2 } from "src/components/Paradise/ParadiseSharedStyles"
import { LoadingWrapper } from "src/components/Paradise/sharedStyles"
import { useFetchOrganization } from "src/data/organizations/queries/organizationQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import Svg404 from "src/ui/icons/404.svg"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"

export function ParadiseOrganizationDetails({ orgId }: { orgId: string }) {
  const { t } = useTranslate()

  const fetchOrganization = useFetchOrganization({
    id: orgId,
  })

  if (fetchOrganization.isError) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  if (fetchOrganization.isLoading) {
    return (
      <LoadingWrapper>
        <MSkeleton height={40} width="30ch" />
        <MSkeleton height={20} width="40ch" />
      </LoadingWrapper>
    )
  }

  const organization = fetchOrganization.data

  return (
    <ParadiseDetailsLayout
      meta={<DetailsHeading2>{organization.name}</DetailsHeading2>}
      left={<ParadiseOrganizationOverview organization={organization} />}
      actions={<ParadiseOrganizationActions organization={organization} />}
      right={
        <>
          <ParadiseOrganizationMembers orgId={organization.id} />
          <ParadiseOrganizationHomes orgId={organization.id} />
          <ParadiseOrganizationHomeGroups orgId={organization.id} />
          <ParadiseOrganizationAPIClients orgId={organization.id} />
          <ParadiseOrganizationDevices orgId={organization.id} />
        </>
      }
      breadcrumbs={[
        {
          label: "Organizations",
          to: Routes.ParadiseOrganizations.location(),
        },
        {
          label: organization.id,
          to: Routes.ParadiseOrganization.location(organization.id),
        },
      ]}
      breadcrumbCopyValue={organization.id}
    />
  )
}
