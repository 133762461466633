import { ParadiseOrganizationTags } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationOverview/ParadiseOrganizationTags/ParadiseOrganizationTags"
import { ParadiseOverviewExpandable } from "src/components/Paradise/ParadiseOverviewExpandable"
import { ParadiseSettingStatic } from "src/components/Paradise/ParadiseSettings/ParadiseSettingStatic"
import { CopyWrapper } from "src/components/Paradise/sharedStyles"
import { IOrganization } from "src/data/organizations/types/organizationTypes"
import { Routes } from "src/router/routes"
import { CopyText } from "src/ui/CopyText/CopyText"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { formatDate } from "src/utils/l10n"

export function ParadiseOrganizationOverview({
  organization,
}: {
  organization: IOrganization
}) {
  return (
    <ParadiseOverviewExpandable rawJson={organization}>
      <ParadiseSettingStatic
        title="Owner id"
        value={
          organization.owner_id ? (
            <CopyWrapper>
              <InternalLink
                to={Routes.ParadiseUser.location(organization.owner_id)}
              >
                {organization.owner_id}
              </InternalLink>
              <CopyText
                value={organization.owner_id}
                copiedText="Copied owner id"
                width={18}
              />
            </CopyWrapper>
          ) : (
            <MText variant="bodyS">-</MText>
          )
        }
      />
      <ParadiseSettingStatic
        title="Created at"
        value={
          organization.created_at
            ? formatDate({ date: organization.created_at, timezone: "utc" })
            : "-"
        }
      />

      <ParadiseOrganizationTags
        currentTags={organization.tags}
        orgId={organization.id}
      />
    </ParadiseOverviewExpandable>
  )
}
