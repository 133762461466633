import { useState } from "react"

import { Pager } from "src/components/Pager/Pager"
import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseImportHomesDialog } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationHomes/ParadiseImportHomesDialog"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { InternalLink } from "src/ui/Link/InternalLink"
import { formatDate } from "src/utils/l10n"

const LIMIT = 15

export function ParadiseOrganizationHomes({ orgId }: { orgId: string }) {
  const { navigate } = useRouter()

  const [showImportHomesDialog, setShowImportHomesDialog] = useState(false)
  const [offset, setOffset] = useState(0)

  const fetchHomes = useFetchHomes({
    orgId,
    filters: {
      offset,
      limit: LIMIT,
    },
  })

  const headers = [
    <div key={"home-id"}>Home id</div>,
    <div key={"name"}>Name</div>,
    <div key={"created-at"}>Created at</div>,
    <div key={"timezone"}>Timezone</div>,
  ]

  const rows =
    fetchHomes.data?.homes.map((home) => (
      <>
        <InternalLink
          to={Routes.ParadiseHome.location(home.home_id)}
          onClick={(e) => e.stopPropagation()}
        >
          {home.home_id}
        </InternalLink>
        <div>{home.name}</div>
        <div>{formatDate({ date: home.created_at, timezone: "utc" })}</div>
        <div>{home.timezone}</div>
      </>
    )) ?? []

  return (
    <>
      <div>
        <ParadiseDetailsSection
          title="Homes"
          onButtonClick={() => setShowImportHomesDialog(true)}
          buttonLabel="Import homes"
        />
        <ParadiseTable
          header={headers}
          rows={rows}
          onRowClick={(index) => {
            if (fetchHomes.data?.homes) {
              navigate(
                Routes.ParadiseHome.location(
                  fetchHomes.data.homes[index].home_id
                )
              )
            }
          }}
        />
        <Pager
          limit={LIMIT}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchHomes.data?.paging.total_count}
        />
      </div>
      <ParadiseImportHomesDialog
        orgId={orgId}
        open={showImportHomesDialog}
        onClose={() => setShowImportHomesDialog(false)}
      />
    </>
  )
}
