import { useRef, useState } from "react"
import styled from "styled-components"

import { StyledDivider } from "src/components/AppLayout/SideNav/sharedStyles"
import { ParadiseDropZone } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationHomes/ParadiseDropZone"
import {
  ParadiseCSVRequirements,
  TCSVColumn,
} from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationHomes/ParadiseFileRequirements"
import { usePostCsvHomes } from "src/data/paradise/paradiseHomes/queries/paradiseHomeQueries"
import { colorsV2 } from "src/ui/colors"
import { MDialog } from "src/ui/Dialog/MDialog"
import { ExpandableAttentionCard } from "src/ui/ExpandableSection/ExpandableAttentionCard"
import { ExpandableSection } from "src/ui/ExpandableSection/ExpandableSection"
import FileUploadIcon from "src/ui/icons/file-upload.svg"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const CSV_COLUMNS: TCSVColumn[] = [
  {
    name: "homename",
    required: true,
  },
  {
    name: "timezone",
    required: true,
  },
  {
    name: "streetName1",
    required: true,
  },
  {
    name: "streetName2",
    required: false,
  },
  {
    name: "postCode",
    required: true,
  },
  {
    name: "country",
    required: true,
  },
  {
    name: "city",
    required: true,
  },
  {
    name: "region",
    required: false,
  },
  {
    name: "notes",
    required: false,
  },
  {
    name: "profileId",
    required: false,
  },
  {
    name: "homegroupId",
    required: false,
  },
]

export function ParadiseImportHomesDialog({
  orgId,
  open,
  onClose,
}: {
  orgId: string
  open: boolean
  onClose: () => void
}) {
  const [file, setFile] = useState<File | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const postCsvHomes = usePostCsvHomes()

  async function handleImport() {
    try {
      if (file) {
        const fileContent = await file.text()

        postCsvHomes.mutate(
          {
            orgId: orgId,
            body: fileContent,
          },
          {
            onSuccess: () => {
              resetState()
            },
          }
        )
      }
    } catch (error) {
      resetState()
    }
  }

  function resetState() {
    if (inputRef.current) {
      inputRef.current.value = ""
    }
    setFile(null)
  }

  return (
    <MDialog
      title="Import homes"
      open={open}
      onClose={() => {
        resetState()
        postCsvHomes.reset()
        onClose()
      }}
      confirmLabel="Import"
      onConfirm={() => {
        handleImport()
      }}
      confirmButtonProps={{
        loading: postCsvHomes.isLoading,
        disabled: !file,
      }}
    >
      {postCsvHomes.data?.homes_imported && (
        <MBanner type="good" fullWidth>
          {postCsvHomes.data.homes_imported} homes imported
        </MBanner>
      )}
      {postCsvHomes.isError && <ImportErrorAlert error={postCsvHomes.error} />}

      <ParadiseDropZone
        ref={inputRef}
        accept="text/csv"
        hint="CSV only"
        hasError={false}
        onChange={setFile}
      />
      {file && (
        <FileBox>
          <div>
            <FileUploadIcon width={24} />
          </div>
          <div>
            <MText variant="subtitle">{file.name}</MText>
            <MText variant="nano">{getSize(file.size)}</MText>
          </div>
        </FileBox>
      )}
      <RequirementsWrapper>
        <ExpandableSection title="File requirements">
          <ParadiseCSVRequirements columns={CSV_COLUMNS} />
        </ExpandableSection>
      </RequirementsWrapper>
    </MDialog>
  )
}

function ImportErrorAlert({
  error,
}: {
  error: ReturnType<typeof usePostCsvHomes>["error"]
}) {
  if (!error?.response) {
    return (
      <MBanner type="error" fullWidth>
        An error occured while uploading CSV
      </MBanner>
    )
  }

  if ("errors" in error.response.data) {
    return (
      <ExpandableAttentionCard
        title="Failed to parse CSV (expand to view errors)"
        type="emergency"
      >
        {error.response.data.errors.map((error, i, arr) => (
          <div key={i}>
            <RowErrorWrapper>
              <MText>Row: {error.row}</MText>
              <MBadge size="small" color="neutral" borderRadius="square">
                {error.error_key}
              </MBadge>
            </RowErrorWrapper>

            <MText variant="subtitle">{error.error}</MText>

            {i < arr.length - 1 && <StyledDivider />}
          </div>
        ))}
      </ExpandableAttentionCard>
    )
  } else {
    return (
      <MBanner type="error" fullWidth>
        {error.response.data.message}
      </MBanner>
    )
  }
}

const DECIMALS = 1
function getSize(size: number) {
  if (size < 1024) {
    return `${size.toFixed(DECIMALS)} bytes`
  }
  if (size >= 1024 && size < 1024 * 1024) {
    return `${(size / 1024).toFixed(DECIMALS)} KB`
  }

  if (size >= 1024 * 1024 && size < 1024 ** 3) {
    return `${(size / (1024 * 1024)).toFixed(DECIMALS)} MB`
  }

  if (size >= 1024 ** 3) {
    return `${(size / 1024 ** 3).toFixed(DECIMALS)} GB`
  }
}

const FileBox = styled.div`
  display: inline-flex;
  gap: ${spacing.M};
  align-items: center;
  padding: ${spacing.M};
  border: 1px solid ${colorsV2.divider};
  border-radius: 5px;
  margin-top: ${spacing.M};
`

const RequirementsWrapper = styled.div`
  margin-top: ${spacing.M};
`

const RowErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.M};
`
